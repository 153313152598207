import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { LibraryreportsService } from '../../services/libraryreports.service';
import { LookupsService } from '../../services/lookups.service';
import { ShareddataService } from '../../services/shareddata.service';

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss'],
})
export class FilterComponent implements OnInit {
  constructor(public bsModalRef: BsModalRef , private router : Router , private dataservice :ShareddataService,
    private dataApi:LibraryreportsService , private lookupApi :LookupsService) {}
[x:string]:any;
myForm!:FormGroup
pipe = new DatePipe('en-US'); 
tempArr1: any = [];
tempArr4: any = [];
tempArr5: any = [];
tempArr7: any = [];
tempArr8: any = [];
tempArr9: any = [];
tempArr15: any = [];
tempArr16: any = [];
finalobj:any = {};
  ngOnInit(): void {
   this.typeLibrary = localStorage.getItem("typeLibrary");
    console.log(this.router.url)
   this.myForm = new FormGroup({
    'reportDate':new FormControl('',[Validators.required] ),  
    'lookupId1Or2Or3':new FormControl(''),  
    'lookupId4':new FormControl(''),  
    'lookupId5':new FormControl(''),  
    'lookupId7':new FormControl(''),  
    'lookupId8':new FormControl(''),  
    'lookupId9':new FormControl(''),  
    'lookupId15':new FormControl(''),
    'lookupId16':new FormControl(''),
   });

   this.lookupApi.getlookupsFilter(4 , null).subscribe(Response=> {
    console.log(Response)
    if(Response.clientMessage == "SUCCESS"){ 
      this.lookupID4 = Response.body;
    }else{
      this.lookupID4 = []
     }
  });

  this.lookupApi.getlookupsFilter(5 , null).subscribe(Response=> {
    console.log(Response)
    if(Response.clientMessage == "SUCCESS"){ 
      this.lookupID5 = Response.body;
    }else{
      this.lookupID5 = []
     }
  });

   this.lookupApi.getlookupsFilter(7 , null).subscribe(Response=> {
    console.log(Response)
    if(Response.clientMessage == "SUCCESS"){ 
      this.lookupID7 = Response.body;
    }else{
      this.lookupID7 = []
     }
  });

  this.lookupApi.getlookupsFilter(8 , null).subscribe(Response=> {
    console.log(Response)
    if(Response.clientMessage == "SUCCESS"){ 
      this.lookupID8 = Response.body;
    }else{
      this.lookupID8 = []
     }
  });

  this.lookupApi.getlookupsFilter(9 , null).subscribe(Response=> {
    console.log(Response)
    if(Response.clientMessage == "SUCCESS"){ 
      this.lookupID9 = Response.body;
    }else{
      this.lookupID9 = []
     }
  });

  this.lookupApi.getlookupsFilter(15 , null).subscribe(Response=> {
    console.log(Response)
    if(Response.clientMessage == "SUCCESS"){ 
      this.lookupID15 = Response.body;
    }else{
      this.lookupID15 = []
     }
  });

  this.lookupApi.getlookupsFilter(16 , null).subscribe(Response=> {
    console.log(Response)
    if(Response.clientMessage == "SUCCESS"){ 
      this.lookupID16 = Response.body;
    }else{
      this.lookupID16 = []
     }
  });
  }

  onChangeLookup4(event, l: any){ 
    this.tempArr4.push(l.lookupId);
    console.log(this.tempArr4)
  }
  onChangeLookup5(event, l: any){ 
    this.tempArr5.push(l.lookupId);
    console.log(this.tempArr5)
  }

  onChangeLookup7(event, l: any){ 
    this.tempArr7.push(l.lookupId);
    console.log(this.tempArr7)
  }
  onChangeLookup8(event, l: any){ 
    this.tempArr8.push(l.lookupId);
    console.log(this.tempArr8)
  }

  onChangeLookup9(event, l: any){ 
    this.tempArr9.push(l.lookupId);
    console.log(this.tempArr9)
  }

  onChangeLookup15(event, l: any){ 
    this.tempArr15.push(l.lookupId);
    console.log(this.tempArr15)

  }

  onChangeLookup16(event, l: any){ 
    this.tempArr16.push(l.lookupId);
    console.log(this.tempArr16)
  }

  resultSearch(_f:any){
    sessionStorage.setItem("searchResult" ,  JSON.stringify([]))
    // alert(this.typeLibrary)


    this.bsModalRef.hide();


    console.log(_f)
    console.log(this.myForm.value)

    
    if(this.typeLibrary == 1){
      this.routerURL = '/reports/search' ;  
     }
    if(this.typeLibrary == 2){
      this.routerURL = '/news/search' ;  

    }
    if(this.typeLibrary == 3){
      this.routerURL = '/articles/search' ;  

    }
    if(this.typeLibrary == 4){
      this.obj.libraryType=[this.typeLibrary]
      this.routerURL = '/statistics/search' ;  

    }

    if((this.router.url).includes('media') ){
      this.routerURL = '/media/search' ;  

    }

    
    this.obj={
      "libraryType": [this.typeLibrary],
      "reportDateFrom":this.pipe.transform(this.myForm.value?.reportDate[0], 'yyyy-MM-dd'),
      "reportDateTo": this.pipe.transform(this.myForm.value?.reportDate[1], 'yyyy-MM-dd'),
      "lookupId1Or2Or3": this.tempArr1,
      "lookupId7": this.tempArr7,
      "lookupId8": this.tempArr8,
      "lookupId9": this.tempArr9,
      "lookupId15": this.tempArr15,
      "lookupId16":this.tempArr16
    }
    console.log(this.obj);

    Object.keys(this.obj).forEach(key => {
     // console.log(key);
      //  console.log("key: ", key);
      //   console.log("Value: ", this.obj[key]); 
      //  console.log("typeof: ", typeof(this.obj[key]));
        if(typeof(this.obj[key]) === 'object' && this.obj[key] !== null){
          // console.log("len: ", this.obj[key].length );
          if(this.obj[key].length !== undefined && this.obj[key].length > 0){
            // this.finalobj.push({[key] : this.obj[key]})
            this.finalobj[key] = this.obj[key]
          }
        }else if(typeof(this.obj[key]) === 'string'){
          this.finalobj[key] = this.obj[key]
        }

      });
      console.log(this.finalobj)
    this.dataApi.searchlibraryReports(this.finalobj).subscribe((Response :any)=> {
      console.log(Response)
      if(Response.clientMessage == "SUCCESS"){ 
        this.searchResult = Response.body;
        // localStorage.setItem("searchResult" ,  JSON.stringify(this.searchResult))
          this.dataservice.saveDataSearch(this.searchResult);
          this.router.navigate([this.routerURL]);

       // window.history.go();
      }else{
        this.dataservice.saveDataSearch([]);
        this.router.navigate([this.routerURL]);

       // localStorage.setItem("searchResult" ,  JSON.stringify([]))
       // window.history.go();

        // bootbox.alert({title: "<span style='color:#a33;font-weight: 500; font-size: 16px'>" + "Warning message" + "</span>", message: "<span style='color:Black;font-weight: 500; font-size: 16px'>" + Response.clientMessage + "</span>"});
       }
    },
    (error) => {                              
      console.log(error);
    }

  );


  }

  
}



