import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class LookupsService {
  URL = `${environment.BASE_URL}`;
  private emitChangeSource = new Subject<any>();
  changeEmitted$ = this.emitChangeSource.asObservable();
  constructor(private http: HttpClient) {}

  emitChange(change: any) {
    this.emitChangeSource.next(change);
  }

  getlookups(lookupID: any): Observable<any> {
    return this.http.get(this.URL + `/lookups/${lookupID}`);
  }

  getlookupsFilter(lookupParentId: any,lookupDependCode: any): Observable<any> {
    if (lookupDependCode == null) {
      return this.http.get(this.URL + `/lookups/Filter?lookupParentId=${lookupParentId}&lookupDependCode=`);
    } else {
      return this.http.get(this.URL + `/lookups/Filter?lookupParentId=${lookupParentId}&lookupDependCode=${lookupDependCode}`);
    }
  }

  saveLookup(sentData) {
    return this.http.post(this.URL + `/lookups`, sentData);
  }

  editLookup(sentData) {
    return this.http.put(this.URL + `/lookups`, sentData);
  }

  deleteLookup(id) {
    return this.http.delete(this.URL + `/lookups/${id}`);
  }

  
  getpersons(): Observable<any> {
    return this.http.get(this.URL + `/persons`);
  }

}
