import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ShareddataService {

  [x:string]:any;
  constructor() { }

  saveDataSearch(searchResult: any) {
    sessionStorage.setItem("searchResult" ,  JSON.stringify(searchResult))
  }

  getDataSearch(){
    // console.log("get");
    var z=sessionStorage.getItem("searchResult");
    if(z!=null)
    this.searchResult = JSON.parse(z);
    console.log(this.searchResult)
    return this.searchResult;
  }

}
