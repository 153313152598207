import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Subject } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';

@Injectable()
export class AuthService {
  token: string;
  httpOptions;
  // private emitToken = new Subject<any>();
  // tokenChangeEmitted$ = this.emitToken.asObservable();
  private emitChange = new Subject<any>();
  userDataChanged$ = this.emitChange.asObservable();
  constructor(private http: HttpClient, private cookieService: CookieService) {
    // this.tokenChangeEmitted$.subscribe((token) => {
    //   this.token = token;
    //   this.httpOptions = {
    //     headers: new HttpHeaders({
    //       Authorization: 'Bearer ' + token,
    //     }),
    //   };
    // });

    this.httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.cookieService.get('media_token'),
      }),
    };
  }

  // emitTokenChange(change: any) {
  //   this.emitToken.next(change);
  // }

  emitUserData(change: any) {
    this.emitChange.next(change);
  }

  signin(sentData) {
    const URL = `${environment.BASE_URL}/login`;
    return this.http.post(URL, sentData);
  }

  getUserData(id) {
    const URL = `${environment.BASE_URL}/users/${id}`;
    return this.http.get(URL, this.httpOptions);
  }


  
}
