import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import jwt_decode from 'jwt-decode';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  URL = `${environment.BASE_URL}`;
  // URL= "http://5.189.140.13:8086";

  constructor(private http: HttpClient) {}
  login(_f: any) {
    console.log(_f);
    return this.http.post<any>(this.URL + '/auth/login', _f).pipe(
      map((response) => {
        console.log('response');
        console.log(response);
        sessionStorage.setItem('token', 'Bearer' + ' ' + response.body.token);
        sessionStorage.setItem('username', _f.username);
        sessionStorage.setItem('roles', response.body.roles);
        sessionStorage.setItem('indexTab', '0');
        return response;
      })
    );
  }

  logoutUser() {
    sessionStorage.clear();
    localStorage.clear();
  }

  getToken(): string {
    return sessionStorage.getItem('token') || '';
  }

  getTokenExpirationDate(token: string): any {
    token = this.getToken();
    const decoded: any = jwt_decode(token);

    if (decoded.exp === undefined) return null;

    const date = new Date(0);
    date.setUTCSeconds(decoded.exp);
    return date;
  }

  isTokenExpired(token?: string): boolean {
    //debugger;
    if (!token) token = this.getToken();
    // console.log(token);
    if (!token || token == 'undefined') return false;

    const date = this.getTokenExpirationDate(token);
    if (date === undefined) return false;
    const d = date.valueOf();
    const nd = new Date().valueOf();
    const r = !(date.valueOf() > new Date().valueOf());
    return !(date.valueOf() > new Date().valueOf());
  }

  getAuthStatus(): boolean {
    if (sessionStorage.getItem('token')) {
      console.log('token');
      return true;
    } else {
      return false;
    }
  }

  //Check Permisson
  public checkAuthPermisson(actionPermission: string) {
    // debugger;
    let Allpermissions = sessionStorage.getItem('roles');
    let perms = JSON.stringify(Allpermissions ? Allpermissions : '');
    console.log('Allpermissions');
    console.log(perms);
    return perms.includes(actionPermission) ? true : false;
  }
}

// getUserScopes(){
//   //const token = this.getToken();
//   //const decodedToken: string[] = jwt_decode(token?.split(" ")[1] || '');
//   //let scopesStored = sessionStorage.getItem("scopes");
//   //let scopes = JSON.parse(scopesStored? scopesStored : "");
//   let permissionsStored = sessionStorage.getItem("permissions");
//   return JSON.parse(permissionsStored? permissionsStored : "");;
// }

// public checkAuth(actionPermission: string){
//   //const token = sessionStorage.getItem("token");
//   //const decodedToken: string[] = jwt_decode(token?.split(" ")[1] || '');
//   //return decodedToken["roles"].includes(actionPermission) ?  true : false;
//   let permissionsStored = sessionStorage.getItem("permissions");
//   let permissions = JSON.parse(permissionsStored? permissionsStored : "");
//   //console.log("permi ", permissions);
//   return permissions.includes(actionPermission) ?  true : false;
// }
