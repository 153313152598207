import { Routes, RouterModule } from '@angular/router';

export const Full_ROUTES: Routes = [
  {
    path: 'home',
    loadChildren: () =>
      import('../../pages/home/home.module').then((m) => m.HomeModule),
  },

  {
    path: 'reports',
    loadChildren: () =>
      import('../../pages/periodic-reports/periodic-reports.module').then(
        (m) => m.PeriodicReportsModule
      ),
  },
  {
    path: 'news',
    loadChildren: () =>
      import('../../pages/periodic-reports/periodic-reports.module').then(
        (m) => m.PeriodicReportsModule
      ),
    // loadChildren: () =>
    //   import('../../pages/news/news.module').then((m) => m.NewsModule),
  },
  {
    path: 'articles',
    loadChildren: () =>
      import('../../pages/periodic-reports/periodic-reports.module').then(
        (m) => m.PeriodicReportsModule
      ),
    // loadChildren: () =>
    //   import('../../pages/articles/articles.module').then((m) => m.ArticlesModule),
  },
  {
    path: 'media',
    loadChildren: () =>
      import('../../pages/media/media.module').then((m) => m.MediaModule),
  },
  {
    path: 'settings',
    loadChildren: () =>
      import('../../pages/settings/settings.module').then(
        (m) => m.SettingsModule
      ),
  },

  {
    path: 'statistics',
    loadChildren: () =>
      import('../../pages/statistics/statistics.module').then(
        (m) => m.StatisticsModule
      ),
  },

  {
    path: 'influencers',
    loadChildren: () =>
      import('../../pages/influencers/influencers.module').then(
        (m) => m.InfluencersModule
      ),
  },

  {
    path: 'peoples',
    loadChildren: () =>
      import('../../pages/peoples/peoples.module').then((m) => m.PeoplesModule),
  },

  {
    path: 'socialmedia',
    loadChildren: () =>
      import('../../pages/socialmedia/socialmedia.module').then(
        (m) => m.SocialmediaModule
      ),
  },
  {
    path: 'impact-analysis',
    loadChildren: () =>
      import('../../pages/impact-analysis/impact-analysis.module').then(
        (m) => m.ImpactAnalysisModule
      ),
  },
  {
    path: 'calendar',
    loadChildren: () =>
      import('../../pages/calendar/calendar.module').then(
        (m) => m.CalenderModule
      ),
  },
  {
    path: 'events',
    loadChildren: () =>
      import('../../pages/events/events.module').then(
        (m) => m.EventsModule
      ),
  },
  {
    path: 'reports-sets',
    loadChildren: () =>
      import('../../pages/reports/reports.module').then((m) => m.ReportsModule),
  },
];
