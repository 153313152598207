import {
  AfterViewChecked,
  Component,
  EventEmitter,
  Output,
} from '@angular/core';
import { NavigationEnd, Router, RoutesRecognized } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { filter, pairwise } from 'rxjs/operators';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements AfterViewChecked {
  @Output() directionEvent = new EventEmitter<Object>();
  @Output() toggleHideSidebar = new EventEmitter<Object>();
  Notificationcount = 0;
  [x: string]: any;
  dictionary = {
    news: 'الأخبار',
    articles: 'التحقيقات ومقالات الرأي',
    media: 'الصور والفيديوهات',
    create: 'إضافة',
    createalbum: 'إضافة ألبوم',
    reports: 'التقارير',
    statistics: 'الاحصائيات والبيانات الرقمية',
    socialmedia: 'حسابات التواصل الاجتماعي',
    influencers: 'إدارة المؤثرين',
    'impact-analysis': 'الرصد وتحليل الأثر',
    calendar: 'الأجندة',
    settings: 'إعدادات النظام',
    peoples: 'الأشخاص',
    view: 'التفاصيل',
    details: 'التفاصيل',
    "reports-sets": "مجاميع التقارير",
    show: 'عرض'
  };
  current_route;
  constructor(private authService: AuthService, private router: Router) {
    this.current_route = router.url;
    this.router.events
      .pipe(
        filter((evt: any) => evt instanceof NavigationEnd),
        pairwise()
      )
      .subscribe((events: NavigationEnd[]) => {
        this.current_route = events[1].urlAfterRedirects;
      });
    this.userName = sessionStorage.getItem('username');
  }
  ngAfterViewChecked() {}

  toggleSidebar() {
    const appSidebar = document.getElementsByClassName('app-sidebar')[0];
    if (appSidebar.classList.contains('hide-sidebar')) {
      this.toggleHideSidebar.emit(false);
    } else {
      this.toggleHideSidebar.emit(true);
    }
  }

  logout() {
    this.authService.logoutUser();
  }

  findInDictionary(value) {
    return this.dictionary[value];
  }
}
