import {
  Component,
  ElementRef,
  EventEmitter,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { ConfigService } from '../../services/config.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
  @ViewChild('toggleIcon') toggleIcon: ElementRef;
  @Output() toggleHideSidebar = new EventEmitter<Object>();

  depth: number;
  activeTitle: string;
  activeTitles: string[] = [];
  expanded: boolean;
  nav_collapsed_open = false;
  logoUrl = 'assets/img/logo.png';
  public config: any = {};
  reportsLibIds = {
    news: 2,
    articles: 3,
     reports: 1,
    statistics: 4,
  };
  constructor(
    private renderer: Renderer2,
    private configService: ConfigService,
    private authService: AuthService,
    private router: Router
  ) {
    if (this.depth === undefined) {
      this.depth = 0;
      this.expanded = true;
    }
    localStorage.setItem(
      'typeLibrary',
      this.reportsLibIds[router.url.split('/')[1]]
    );
  }

  ngOnInit() {
    this.config = this.configService.templateConf;
  }

  ngAfterViewInit() {
    setTimeout(() => {
      if (this.config.layout.sidebar.collapsed != undefined) {
        if (this.config.layout.sidebar.collapsed === true) {
          this.expanded = false;
          this.renderer.addClass(
            this.toggleIcon.nativeElement,
            'mdi-arrow-left-circle-outline'
          );
          this.renderer.removeClass(
            this.toggleIcon.nativeElement,
            'mdi-arrow-right-circle-outline'
          );
          this.nav_collapsed_open = true;
        } else if (this.config.layout.sidebar.collapsed === false) {
          this.expanded = true;
          this.renderer.removeClass(
            this.toggleIcon.nativeElement,
            'mdi-arrow-left-circle-outline'
          );
          this.renderer.addClass(
            this.toggleIcon.nativeElement,
            'mdi-arrow-right-circle-outline'
          );
          this.nav_collapsed_open = false;
        }
      }
    }, 0);
  }

  toggleSlideInOut() {
    this.expanded = !this.expanded;
  }

  toggleSidebar() {
    const appSidebar = document.getElementsByClassName('app-sidebar')[0];
    if (appSidebar.classList.contains('hide-sidebar')) {
      this.toggleHideSidebar.emit(false);
    } else {
      this.toggleHideSidebar.emit(true);
    }
  }

  handleToggle(titles) {
    this.activeTitles = titles;
  }

  logout() {
    this.authService.logoutUser();
  }

  changeTypeLibrary(typeLibrary: any) {
    // alert(typeLibrary);
    localStorage.setItem('typeLibrary', typeLibrary);
  }
}
