import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {ButtonModule} from 'primeng/button';
import {ListboxModule} from 'primeng/listbox';
import {InputTextModule} from 'primeng/inputtext';
import {BreadcrumbModule} from 'primeng/breadcrumb';
import {SplitButtonModule} from 'primeng/splitbutton';
import {InputTextareaModule} from 'primeng/inputtextarea';
import {InputMaskModule} from 'primeng/inputmask';
import {PasswordModule} from 'primeng/password';
 import {ToastModule} from 'primeng/toast';
 import {CalendarModule} from 'primeng/calendar';
 import {SliderModule} from 'primeng/slider';
 import {ContextMenuModule} from 'primeng/contextmenu';
 import {DialogModule} from 'primeng/dialog';
 import {ProgressBarModule} from 'primeng/progressbar';
 import {MenubarModule} from 'primeng/menubar';
 import {ToolbarModule} from 'primeng/toolbar';
 import {PanelMenuModule} from 'primeng/panelmenu';
 import {RippleModule } from 'primeng/ripple';
 import {RadioButtonModule} from 'primeng/radiobutton';
 import {CheckboxModule} from 'primeng/checkbox';
 import {TabViewModule} from 'primeng/tabview';
 import {CarouselModule} from 'primeng/carousel';
 import {PanelModule } from "primeng/panel";
 import {CardModule} from 'primeng/card';
 import {AccordionModule} from 'primeng/accordion';
 import {TooltipModule} from 'primeng/tooltip';

 import {ConfirmPopupModule} from 'primeng/confirmpopup';
 import {MessagesModule} from 'primeng/messages';
 import {MessageModule} from 'primeng/message';
 import {ProgressSpinnerModule} from 'primeng/progressspinner';
 import {SidebarModule} from 'primeng/sidebar';
 import {FieldsetModule} from 'primeng/fieldset';

 // import {TreeModule} from 'primeng/tree';
// import {MultiSelectModule} from 'primeng/multiselect';
 //import {DropdownModule} from 'primeng/dropdown';
 //import {TableModule} from 'primeng/table';
// import {PaginatorModule} from 'primeng/paginator';
// import {DataViewModule} from 'primeng/dataview';
// import {GMapModule} from 'primeng/gmap';

const primengModules = [
  ButtonModule,
  ListboxModule,
  InputTextModule,
  BreadcrumbModule,
  InputTextModule,
  SidebarModule,
  SplitButtonModule,
  PasswordModule,
  InputMaskModule,
  InputTextareaModule,
   ToolbarModule,
   PanelMenuModule,
   MenubarModule,
   CalendarModule,
   SliderModule,
   DialogModule,
   ContextMenuModule,
   ToastModule,
   ProgressBarModule,
   RippleModule,
   RadioButtonModule,
   CheckboxModule,
   TabViewModule,
   CarouselModule,
   PanelModule,
   CardModule,
   AccordionModule,
  // GMapModule,
   // DataViewModule,
   // TreeModule,
 // DropdownModule,
  // MultiSelectModule,
  //TableModule,
  // PaginatorModule,
   ConfirmPopupModule,
   TooltipModule,
   MessageModule,
   MessagesModule,
   ProgressSpinnerModule,
   FieldsetModule
  // ScrollTopModule,
  //  BadgeModule,
  //  ChipModule,
  //  DividerModule,
  //  SplitterModule,
  //  OrganizationChartModule,


];

@NgModule({
  imports: [
    CommonModule,
    ...primengModules
  ],
  exports: [
    ...primengModules
  ],
  
})


export class PrimengModule { }

//npm install @angular/cdk --save
