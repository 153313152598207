import { HelperToolsService } from '../services/helper-tools.service';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { Observable } from 'rxjs';

@Injectable()
export class LoginGuard implements CanActivate {
  newToken;
  constructor( private cookieService: CookieService, private router: Router, private authService : AuthService) {
    // this.authService.tokenChangeEmitted$.subscribe((token) => {
    //   this.newToken = token;
    // });
  }


  
  // canActivate():boolean|Observable<boolean>{
  // //  var isAuthenticated = this.authService.getAuthStatus();
  //  //console.log(isAuthenticated);
  //  var token = sessionStorage.getItem('token');
  //  if(token)
  //  {
  //    return true
  //  }
  //  this.router.navigateByUrl("");
  //  return false;
  // }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return new Promise<boolean>((resolve, reject) => {
      let token = this.cookieService.get('media_token') || this.newToken;
      // console.log(token);
      if (token) {
        this.router.navigate(['/news']);
        resolve(false);
      } else {
        resolve(true);
      }
    });
  }
}
