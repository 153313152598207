<div class="genral-filter">
    <div class="card-header">
        <h4> الفلتر العام </h4>
    </div>

    <form #f="ngForm"  [formGroup]="myForm" (ngSubmit)="resultSearch(f.value)">
        <div class="position-relative">
            <!-- <a role="button" class="d-block filter-item" name="reportDate"  bsDaterangepicker [bsConfig]="{displayMonths :1}"> الفترة</a> -->
           <!-- <span>الفترة</span> -->
           <!-- <a role="button">الفترة <span class="text-danger"> *</span></a> -->
         <input type="text"  class="d-block filter-item"  placeholder=" * الفترة" class="" bsDaterangepicker  
          formControlName="reportDate" [bsConfig]="{displayMonths :1}" style="border: none !important; width: 95%; margin-right: 10px;">
        </div>
        <div dropdown class="position-relative" [insideClick]="true">
            <a role="button" id="button-autoclose1" class="d-block dropdown-toggle filter-item" dropdownToggle aria-controls="dropdown-autoclose1"> جهة النشر 1 </a>
            <ul id="dropdown-autoclose1" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-autoclose1">
                <div class="custom-control custom-checkbox mb-2" *ngFor="let l4 of lookupID4" >
                    <input type="checkbox" class="custom-control-input" id="{{l4.lookupMainName}}" formControlName="lookupID4" 
                     name="{{l4.lookupMainName}}"  [value]="l4.lookupMainName" formControlName="lookupId16"  (change)="onChangeLookup4($event, l4)">
                    <label class="custom-control-label" for="{{l4.lookupMainName}}">{{l4.lookupMainName}}</label>
                </div>
              
            </ul>
        </div>

        <div dropdown class="position-relative" [insideClick]="true">
            <a role="button" id="button-autoclose1" class="d-block dropdown-toggle filter-item" dropdownToggle aria-controls="dropdown-autoclose1"> جهة النشر 2 </a>
            <ul id="dropdown-autoclose1" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-autoclose1">
                <div class="custom-control custom-checkbox mb-2" *ngFor="let l5 of lookupID5" >
                    <input type="checkbox" class="custom-control-input" id="{{l5.lookupMainName}}" formControlName="lookupID5" 
                     name="{{l5.lookupMainName}}"  [value]="l5.lookupMainName" formControlName="lookupId5"  (change)="onChangeLookup5($event, l5)">
                    <label class="custom-control-label" for="{{l5.lookupMainName}}">{{l5.lookupMainName}}</label>
                </div>
            </ul>
        </div>


        <div dropdown class="position-relative" [insideClick]="true">
            <a role="button" id="button-autoclose1" class="d-block dropdown-toggle filter-item" dropdownToggle aria-controls="dropdown-autoclose1"> الأثر </a>
                <ul id="dropdown-autoclose1" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-autoclose1">
                    <div class="custom-control custom-checkbox mb-2" *ngFor="let l16 of lookupID16" >
                        <input type="checkbox" class="custom-control-input" id="{{l16.lookupMainName}}" formControlName="lookupID16" 
                         name="{{l16.lookupMainName}}"  [value]="l16.lookupMainName" formControlName="lookupId16"  (change)="onChangeLookup16($event, l16)">
                        <label class="custom-control-label" for="{{l16.lookupMainName}}">{{l16.lookupMainName}}</label>
                    </div>
            </ul>
        </div>

        <div dropdown class="position-relative" [insideClick]="true">
            <a role="button" id="button-autoclose1" class="d-block dropdown-toggle filter-item" dropdownToggle aria-controls="dropdown-autoclose1"> الوسيط </a>
            <ul id="dropdown-autoclose1" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-autoclose1">
                <div class="custom-control custom-checkbox mb-2" *ngFor="let l15 of lookupID15" >
                    <input type="checkbox" class="custom-control-input" id="{{l15.lookupMainName}}" formControlName="lookupID15" 
                     name="{{l15.lookupMainName}}"  [value]="l15.lookupMainName" formControlName="lookupId15"  (change)="onChangeLookup15($event, l15)">
                    <label class="custom-control-label" for="{{l15.lookupMainName}}">{{l15.lookupMainName}}</label>
                </div>
                
            </ul>
        </div>

       

        <!-- <div class="mb-2">
            <label class="form-label" id="effect">55 </label>
            <select class="form-control" id="effect"  formControlName="lookupId7" multiple>
                <option [ngValue]="item7.lookupId" *ngFor="let item7 of lookupID7" > {{item7.lookupMainName}} </option>
            </select>
        </div> -->


        <div dropdown class="position-relative" [insideClick]="true">
            <a role="button" id="button-autoclose1" class="d-block dropdown-toggle filter-item" dropdownToggle 
            aria-controls="dropdown-autoclose1"> 1 الموضوع </a>
            <ul id="dropdown-autoclose1" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-autoclose1">
                    <div class="custom-control custom-checkbox mb-2" *ngFor="let l7 of lookupID7" >
                        <input type="checkbox" class="custom-control-input" id="{{l7.lookupMainName}}" formControlName="lookupID7"
                         name="{{l7.lookupMainName}}"  [value]="l7.lookupMainName" formControlName="lookupId7"
                          (change)="onChangeLookup7($event, l7)">
                        <label class="custom-control-label" for="{{l7.lookupMainName}}">{{l7.lookupMainName }}</label>
                    </div>
            </ul>
        </div>

        <div dropdown class="position-relative" [insideClick]="true">
            <a role="button" id="button-autoclose1" class="d-block dropdown-toggle filter-item" dropdownToggle aria-controls="dropdown-autoclose1"> 2 الموضوع </a>
            <ul id="dropdown-autoclose1" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-autoclose1">
                <div class="custom-control custom-checkbox mb-2" *ngFor="let l8 of lookupID8" >
                    <input type="checkbox" class="custom-control-input" id="{{l8.lookupMainName}}" formControlName="lookupID8"
                     name="{{l8.lookupMainName}}"  [value]="l8.lookupMainName" formControlName="lookupId8"  (change)="onChangeLookup8($event, l8)" >
                    <label class="custom-control-label" for="{{l8.lookupMainName}}">{{l8.lookupMainName}}</label>
                </div>
            </ul>
        </div>

        <div dropdown class="position-relative" [insideClick]="true">
            <a role="button" id="button-autoclose1" class="d-block dropdown-toggle filter-item" dropdownToggle aria-controls="dropdown-autoclose1"> 3 الموضوع </a>
            <ul id="dropdown-autoclose1" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-autoclose1">
                <div class="custom-control custom-checkbox mb-2" *ngFor="let l9 of lookupID9" >
                    <input type="checkbox" class="custom-control-input" id="{{l9.lookupMainName}}" formControlName="lookupID9" 
                     name="{{l9.lookupMainName}}"  [value]="l9.lookupMainName" formControlName="lookupId9"  (change)="onChangeLookup9($event, l9)">
                    <label class="custom-control-label" for="{{l9.lookupMainName}}">{{l9.lookupMainName}}</label>
                </div>
                
            </ul>
        </div>

        <div class="btn_group text-center mt-4">
            <button type="button" class="btn btn-danger ml-2" (click)="bsModalRef.hide()"> إلغاء </button>
            <div class=" d-inline-block"> <button type="submit" class="btn btn-success" [disabled]="myForm.invalid" > حفظ
                </button> </div>
        </div>
    </form>
</div>