import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class LibraryreportsService {
  URL = `${environment.BASE_URL}`;
  private emitChange = new Subject<any>();
  setChanged$ = this.emitChange.asObservable();
  constructor(private http: HttpClient) {}

  emitSet(change: any) {
    this.emitChange.next(change);
  }

  getlibraryReports(): Observable<any> {
    return this.http.get(this.URL + `/libraryReports`);
  }

  getlibraryReportsByFilterType(librarytype: any): Observable<any> {
    return this.http.get(
      this.URL + `/libraryReports/librarytype/${librarytype}`
    );
  }

  getReprtsBySetId(librarytype: any, setId: any): Observable<any> {
    return this.http.get(
      this.URL + `/libraryReports/librarytype/${librarytype}/${setId}`
    );
  }

  createlibraryReports(objReport): Observable<any> {
    return this.http.post(this.URL + `/libraryReports`, objReport);
  }

  getlibraryReportsBylibraryid(libraryid: any): Observable<any> {
    return this.http.get(this.URL + `/libraryReports/${libraryid}`);
  }

  updatelibraryReportsFiles(objReport): Observable<any> {
    return this.http.post(this.URL + `/libraryReportsFile`, objReport);
  }

  deleteLibraryReportFile(libraryidFile: any): Observable<any> {
    return this.http.delete(this.URL + `/libraryReportsFile/${libraryidFile}`);
  }

  getlibraryReportsFile(libraryid: any): Observable<any> {
    return this.http.get(
      this.URL + `/libraryReportsFile/getByLibraryId/${libraryid}`
    );
  }

  previewLibraryReportsFile(fileEntity: any, fileName: any): Observable<any> {
    return this.http.get(this.URL + `/previewFile/${fileEntity}/${fileName}`);
  }

  createPhotoLibrary(sentData) {
    return this.http.post(this.URL + `/libraryPhoto`, sentData);
  }
  createMultiPhotoLibrary(sentData) {
    return this.http.post(this.URL + `/libraryPhoto/addMultiple`, sentData);
  }

  uploadFile(sentData: FormData, fileEntity) {
    return this.http.post(
      this.URL + `/uploadFile?fileEntity=${fileEntity}`,
      sentData
    );
  }

  uploadFilemultiple(sentData: FormData, fileEntity) {
    return this.http.post(
      this.URL + `/uploadMultipleFiles?fileEntity=${fileEntity}`,
      sentData
    );
  }
  getDownload(downloadURL: any): Observable<any> {
    return this.http.get(downloadURL);
  }

  getPhotoLibrary() {
    return this.http.get(this.URL + `/libraryPhoto`);
  }

  getPhotoLibraryById(library_id) {
    return this.http.get(this.URL + `/libraryPhoto/${library_id}`);
  }

  updatePhotoLibrary(sentData) {
    return this.http.put(this.URL + `/libraryPhoto`, sentData);
  }

  searchlibraryReports(searchData) {
    console.log(this.URL + `/libraryReports/search`);
    return this.http.post(this.URL + `/libraryReports/search`, searchData);
  }

  getAllalbums() {
    return this.http.get(this.URL + `/libraryPhoto/albums`);
  }

  getalbumsById(album_id) {
    return this.http.get(this.URL + `/libraryPhoto/album/${album_id}`);
  }

  getPhotosByalbumsId(album_id) {
    return this.http.get(this.URL + `/libraryPhoto/photos/${album_id}`);
  }

  createalbums(obj) {
    return this.http.post(this.URL + `/libraryPhoto/album`, obj);
  }

  updatealbums(obj) {
    return this.http.put(this.URL + `/libraryPhoto/album`, obj);
  }

  deletealbum(album_id) {
    console.log(this.URL + `/libraryPhoto/album/${album_id}`);
    return this.http.delete(this.URL + `/libraryPhoto/album/${album_id}`);
  }

  deletePhoto(photo_id) {
    console.log(this.URL + `/libraryPhoto/${photo_id}`);
    return this.http.delete(this.URL + `/libraryPhoto/${photo_id}`);
  }

  deleteReportLib(lib_id) {
    return this.http.delete(this.URL + `/libraryReports/${lib_id}`);
  }

  createSet(sentData) {
    return this.http.post(this.URL + `/libraryReportsSet`, sentData);
  }

  editSet(sentData) {
    return this.http.put(this.URL + `/libraryReportsSet`, sentData);
  }

  getSets() {
    return this.http.get(this.URL + `/libraryReportsSet`);
  }

  deleteSet(id) {
    return this.http.delete(this.URL + `/libraryReportsSet/${id}`);
  }
}
