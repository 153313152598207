<div class="options">
  <a
    class="nav-close d-block d-md-block d-lg-none d-xl-none text-danger"
    id="sidebarClose"
    href="javascript:;"
  >
    <i class="mdi mdi-close"> </i>
  </a>
</div>
<div class="sidebar-main">
  <div class="logo text-center">
    <img src="./assets/images/logo2.png" />
    <img src="./assets/images/small-logo.png" />
    <!-- <strong class="text-primary d-block"> وزارة البيئة والمياه والزراعة </strong>
        <span class="d-block text-primary" style="font-size: 12px;"> Ministry of environment water & agriculture </span>
        <strong class="text-secondary"> وكالة المياه </strong> -->
  </div>
  <div class="links-container">
    <ul class="pt-3 pb-3" style="margin-top: -18px">
      <li routerLinkActive="active">
        <a routerLink="/news" (click)="changeTypeLibrary(2)">
          <i class="mdi mdi-newspaper"></i> الأخبار
        </a>
      </li>
      <li routerLinkActive="active">
        <a routerLink="/articles" (click)="changeTypeLibrary(3)">
          <i class="mdi mdi-comment-multiple-outline"></i> التحقيقات ومقالات
          الرأي
        </a>
      </li>
      <li routerLinkActive="active" (click)="changeTypeLibrary(5)">
        <a routerLink="/media">
          <i class="mdi mdi-folder-image"></i> الصور والفيديوهات
        </a>
      </li>
      <li routerLinkActive="active">
        <a routerLink="/reports-sets">
          <i class="mdi mdi-file"></i> التقارير
        </a>
      </li>
      <li routerLinkActive="active" (click)="changeTypeLibrary(4)">
        <a routerLink="/statistics">
          <i class="mdi mdi-chart-areaspline"></i> الاحصائيات والبيانات الرقمية
        </a>
      </li>

      <li routerLinkActive="active">
        <a routerLink="/socialmedia">
          <i class="mdi mdi-earth"></i> حسابات التواصل الاجتماعي
        </a>
      </li>

      <li routerLinkActive="active">
        <a routerLink="/influencers">
          <i class="mdi mdi-account-group-outline"></i> إدارة المؤثرين
        </a>
      </li>
      <li routerLinkActive="active">
        <a routerLink="/impact-analysis">
          <i class="mdi mdi-chart-areaspline"></i> الرصد وتحليل الأثر
        </a>
      </li>

      <li routerLinkActive="active">
        <a routerLink="/calendar">
          <i class="mdi mdi-calendar-month"></i> الأجندة
        </a>
      </li>
      <li routerLinkActive="active">
        <a routerLink="/events">
          <i class="mdi mdi-party-popper"></i> الفعاليات
        </a>
      </li>
      <li routerLinkActive="active">
        <a routerLink="/settings">
          <i class="mdi mdi-cog"></i> إعدادات النظام
        </a>
      </li>

      <li routerLinkActive="active">
        <a routerLink="/peoples">
          <i class="mdi mdi-account-group-outline"></i> الاشخاص
        </a>
      </li>
    </ul>
    <div class="d-flex align-items-center justify-content-between logout">
      <a routerLink="/login" (click)="logout()">
        <i class="mdi mdi-logout text-danger"></i> تسجيل الخروج
      </a>
      <a
        class="nav-toggle d-none d-sm-none d-md-none d-lg-block text-yellow"
        id="sidebarToggle"
        href="javascript:;"
      >
        <i
          #toggleIcon
          appSidebarToggle
          class="mdi toggle-icon"
          data-toggle="expanded"
        ></i>
      </a>
    </div>
  </div>

  <div class="small-footer">
    <p>Powered by</p>
    <img src="./assets/images/evara-logo-1.jpeg" />
  </div>
</div>
