import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ContentComponent } from './layout/content/content.component';
import { FullComponent } from './layout/full/full.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NavbarComponent } from './shared/components/navbar/navbar.component';
import { SidebarComponent } from './shared/components/sidebar/sidebar.component';
import { FooterComponent } from './shared/components/footer/footer.component';
import { SidebarDirective } from './shared/directives/sidebar.directive';
import { SidebarToggleDirective } from './shared/directives/sidebartoggle.directive';
import { FilterComponent } from './shared/components/filter/filter.component';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { ClickOutsideDirective } from './shared/directives/click-outside.directive';
import { CookieService } from 'ngx-cookie-service';
import { AuthGuard } from './shared/auth/auth.guard';
import { AuthService } from './shared/auth/auth.service';
import { LoginGuard } from './shared/auth/login.guard';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgxSpinnerModule } from 'ngx-spinner';
import { AuthInterceptor } from './shared/interceptor/auth.interceptor';
import { SharedModule } from './shared/shared.module';
import { FullCalendarModule } from '@fullcalendar/angular';
import { PinchZoomModule } from 'ngx-pinch-zoom';
import { ImageComponent } from './shared/components/image/image.component';
import { DndListModule } from 'ngx-drag-and-drop-lists';

@NgModule({
  declarations: [
    AppComponent,
    ContentComponent,
    FullComponent,
    NavbarComponent,
    SidebarComponent,
    FooterComponent,
    SidebarDirective,
    SidebarToggleDirective,
    FilterComponent,
    ClickOutsideDirective,
    ImageComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    BsDropdownModule.forRoot(),
    BsDatepickerModule.forRoot(),
    NgxSpinnerModule,
    SharedModule,
    PinchZoomModule,
    FullCalendarModule, 
    DndListModule
  ],
  providers: [
    CookieService,
    AuthService,
    AuthGuard,
    LoginGuard,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
  ],

  bootstrap: [AppComponent],
})
export class AppModule {}
